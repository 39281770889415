import { Action } from 'redux';

export const SET_DEFAULT_STATE = '[shared] set default state';
export interface SetDummyState extends Action<typeof SET_DEFAULT_STATE> {
	payload: boolean;
}
export const setDummyState = (payload: boolean) => {
	return {
		type: SET_DEFAULT_STATE,
		payload: payload,
	};
};

/* calculator */
export const UPDATE_CALCULATOR_VALUE = '[shared] update calculator values';
export interface UpdateCalculatorValue
	extends Action<typeof UPDATE_CALCULATOR_VALUE> {
	payload: any;
}
export const updateCalculatorValue = (payload: any) => {
	return {
		type: UPDATE_CALCULATOR_VALUE,
		payload: payload,
	};
};

export const RESET_CALCULATOR = '[shared] reset calculator';
export interface ResetCalculator extends Action<typeof RESET_CALCULATOR> {}
export const resetCalculator = () => {
	return {
		type: RESET_CALCULATOR,
	};
};

export const SET_SOCIAL_SERVICE_CALCULATED_DATA =
	'[shared] set social service calculated data';
export interface SetSocialServiceCalculatedData
	extends Action<typeof SET_SOCIAL_SERVICE_CALCULATED_DATA> {
	payload: any;
}
export const SetSocialServiceCalculatedData = (payload: any) => {
	return {
		type: SET_SOCIAL_SERVICE_CALCULATED_DATA,
		payload: payload,
	};
};

export const SET_REVENUE_IMPLICATION_CALCULATED_DATA =
	'[shared] set revenue-implication calculated data';
export interface SetRevenueImplicationCalculatedData
	extends Action<typeof SET_REVENUE_IMPLICATION_CALCULATED_DATA> {
	payload: any;
}
export const SetRevenueImplicationCalculatedData = (payload: any) => {
	return {
		type: SET_REVENUE_IMPLICATION_CALCULATED_DATA,
		payload: payload,
	};
};

export const SET_CURRENT_UTILIZATION_CALCULATED_DATA =
	'[shared] set current utilization calculated data';
export interface SetCurrentUtilizationCalculatedData
	extends Action<typeof SET_CURRENT_UTILIZATION_CALCULATED_DATA> {
	payload: any;
}
export const SetCurrentUtilizationCalculatedData = (payload: any) => {
	return {
		type: SET_CURRENT_UTILIZATION_CALCULATED_DATA,
		payload: payload,
	};
};

export const SET_TARGET_POPULATION_CALCULATED_DATA =
	'[shared] set target population calculated data';
export interface SetTargetPopulationCalculatedData
	extends Action<typeof SET_TARGET_POPULATION_CALCULATED_DATA> {
	payload: any;
}
export const SetTargetPopulationCalculatedData = (payload: any) => {
	return {
		type: SET_TARGET_POPULATION_CALCULATED_DATA,
		payload: payload,
	};
};

export const SET_HEALTHCARE_UTILIZATION_CALCULATED_DATA =
	'[shared] set healthcare utilization calculated data';
export interface SetHealthcareUtilizationCalculatedData
	extends Action<typeof SET_HEALTHCARE_UTILIZATION_CALCULATED_DATA> {
	payload: any;
}
export const SetHealthcareUtilizationCalculatedData = (payload: any) => {
	return {
		type: SET_HEALTHCARE_UTILIZATION_CALCULATED_DATA,
		payload: payload,
	};
};

export const SET_FINANCIAL_RETURN_SUMMARY_CALCULATED_DATA =
	'[shared] set financial return summary calculated data';
export interface SetFinancialReturnSummaryCalculatedData
	extends Action<typeof SET_FINANCIAL_RETURN_SUMMARY_CALCULATED_DATA> {
	payload: any;
}
export const SetFinancialReturnSummaryCalculatedData = (payload: any) => {
	return {
		type: SET_FINANCIAL_RETURN_SUMMARY_CALCULATED_DATA,
		payload: payload,
	};
};

export type SharedActionType =
	| SetDummyState
	| UpdateCalculatorValue
	| ResetCalculator
	| SetSocialServiceCalculatedData
	| SetRevenueImplicationCalculatedData
	| SetCurrentUtilizationCalculatedData
	| SetTargetPopulationCalculatedData
	| SetHealthcareUtilizationCalculatedData
	| SetFinancialReturnSummaryCalculatedData;
