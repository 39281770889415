import './dialog-box.css';

export const DialogBox = (props: any) => {
	return (
		<>
			<div className='justify-center items-center w-full flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
				<div className={`relative w-full max-h-[95%] overflow-y-auto ${props.expanded ? 'max-w-[90%]' :'max-w-[60%]'}`}>
					<div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
						{props.children}
					</div>
				</div>
			</div>
			<div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
		</>
	);
};
