import {
	Action,
	applyMiddleware,
	combineReducers,
	legacy_createStore as createStore,
} from 'redux';
import { SamplesReducer } from '../pages/samples/core/samples.reducer';
import { SharedReducer } from '../shared/core/shared.redcuer';
import { compose } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session';

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig: any = {
	key: 'roi-calculator',
	storage: storageSession,
};

const AppReducer = combineReducers({
	samples: SamplesReducer,
	shared: SharedReducer,
});

const persistedReducer = persistReducer(persistConfig, AppReducer);

export const store = createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware()),
);

export const persistor = persistStore(store);
