import { useState } from 'react';
import { DialogBox } from '../../../../shared/components/dialog-box/dialog-box';
import { Definition } from '../definitaion/definition';

export const DeepDive = () => {
	/* variable */
	let selectedDefinition: any = {};
	let setSelectedDefinition: any;
	/* state */
	const [showModal, setShowModal] = useState(false);
	[selectedDefinition, setSelectedDefinition] = useState(null);

	return (
		<div className='w-full text-black'>
			<div>
				<ol className='list-decimal pl-[2rem]'>
					<li className='font-colfax list-view-content mt-[2rem]'>
						<span className='list-view-label'>Availability of data: </span> The
						Quick Calculator prepopulates the inputs required by the calculator
						with national healthcare utilization data and published evidence on
						the impact of social service interventions. The Deep Dive Calculator
						requires you to manually enter these data such as baseline
						healthcare utilization rates (e.g. ED visits), costs for healthcare
						events (e.g. hospital day), and estimated impact of an intervention
						(e.g. home-delivered meals).
					</li>
					<li className='font-colfax list-view-content mt-[2rem]'>
						<span className='list-view-label'>Types of social services: </span>{' '}
						The Quick Calculator calculates ROI only for select utilization
						outcomes (e.g., reduction in hospital admissions) for the following
						social service interventions:{' '}
						<span
							className='underline cursor-pointer inline definition-clickable'
							onClick={() => {
								setShowModal(true);
								setSelectedDefinition({
									label: 'Supportive Housing',
									content:
										'<b>Supportive Housing </b>combines stable, affordable housing with supportive services to help people experiencing homelessness or people with disabilities stay housed and live a productive life in the community.',
								});
							}}
						>
							Supportive Housing
						</span>
						,{' '}
						<span
							className='underline cursor-pointer inline definition-clickable'
							onClick={() => {
								setShowModal(true);
								setSelectedDefinition({
									label: 'Transitional Housing',
									content:
										'<b>Transitional Housing</b> is temporary affordable housing intended to help people transition from homelessness or another crisis into permanent housing.',
								});
							}}
						>
							Transitional Housing
						</span>
						,{' '}
						<span
							className='underline cursor-pointer inline definition-clickable'
							onClick={() => {
								setShowModal(true);
								setSelectedDefinition({
									label: 'Medical Respite/Recuperative Care',
									content:
										'<b>Medical Respite/Recuperative Care</b> is short-term residential care for people experiencing homelessness to recover and heal from an illness or injury while accessing medical care and other supportive services.',
								});
							}}
						>
							Medical Respite/Recuperative Care
						</span>
						,{' '}
						<span
							className='underline cursor-pointer inline definition-clickable'
							onClick={() => {
								setShowModal(true);
								setSelectedDefinition({
									label: 'Medically Tailored Meals',
									content:
										'<b>Medically Tailored Meals</b> are customized and delivered to meet the medical needs of individuals living with severe or chronic illness, thereby improving their health outcomes and lowering costs of care.',
								});
							}}
						>
							Medically Tailored Meals
						</span>
						,{' '}
						<span
							className='underline cursor-pointer inline definition-clickable'
							onClick={() => {
								setShowModal(true);
								setSelectedDefinition({
									label: 'Meals (Not Medically Tailored)',
									content:
										'<b>Meals (Not Medically Tailored)</b> provide a supplemental source of nutrition to address food insecurity and promote independent living. Prepopulated data for this category reflect only the costs and impact of home-delivery programs such as Meals on Wheels.',
								});
							}}
						>
							Meals (Not Medically Tailored)
						</span>
						,{' '}
						<span
							className='underline cursor-pointer inline definition-clickable'
							onClick={() => {
								setShowModal(true);
								setSelectedDefinition({
									label: 'Legal Assistance',
									content:
										'<b>Legal Assistance </b> includes programs such as medical-legal partnerships that address health-related social needs, such as by advocating with landlords to ameliorate substandard housing conditions that negatively affect health outcomes.',
								});
							}}
						>
							Legal Assistance
						</span>
						,{' '}
						<span
							className='underline cursor-pointer inline definition-clickable'
							onClick={() => {
								setShowModal(true);
								setSelectedDefinition({
									label: 'Multidisciplinary Team',
									content:
										'<p><b>Care Management</b> assists patients and their support network to effectively manage medical conditions and related psychosocial needs, including screening for social needs and referral to social services.</p><p><b>Multidisciplinary Team</b> may include physicians, nurses, social workers, and others who coordinate care for patients with complex needs and connect them with community resources.</p>',
								});
							}}
						>
							Care Management by a Multidisciplinary Team
						</span>
						,{' '}
						<span
							className='underline cursor-pointer inline definition-clickable'
							onClick={() => {
								setShowModal(true);
								setSelectedDefinition({
									label: 'Social Worker',
									content:
										'<p><b>Care Management</b> assists patients and their support network to effectively manage medical conditions and related psychosocial needs, including screening for social needs and referral to social services.</p><p><b>Social Worker</b> are licensed professionals who assess psychosocial needs, provide behavioral health counseling, and connect people to services in the community.</p>',
								});
							}}
						>
							Care Management by a Social Worker
						</span>
						{' and '}
						<span
							className='underline cursor-pointer inline definition-clickable'
							onClick={() => {
								setShowModal(true);
								setSelectedDefinition({
									label: 'Community Health Workers or Navigator',
									content:
										'<p><b>Care Management</b> assists patients and their support network to effectively manage medical conditions and related psychosocial needs, including screening for social needs and referral to social services.</p><p><b>Community Health Workers or Navigator</b> are trained laypeople who assess basic needs, connect people with resources, and address other needs under supervision by a healthcare team.</p>',
								});
							}}
						>
							{' '}
							Care Management by a Community Health Workers or Navigator
						</span>
						. The Deep Dive Calculator allows calculations on a wider range of
						interventions and outcomes.
					</li>
					<li className='font-colfax list-view-content mt-[2rem]'>
						<span className='list-view-label'>Relevance: </span> The Quick
						Calculator uses national data and published evidence that may not be
						fully comparable to your specific use case. The Deep Dive Calculator
						will support a calculation tailored to your identified population
						and intervention. The Quick Calculator can be a good place to start
						to develop a “ballpark” estimate of ROI that can be refined later
						when you have specific information on your population and
						intervention.
					</li>
					<li className='font-colfax list-view-content mt-[2rem]'>
						<span className='list-view-label'>Partner Type: </span> The Quick
						Calculator will not capture losses in revenue due to reductions in
						ED use and hospitalizations. For some healthcare partners, such as
						hospitals paid under fee-for-service, reductions in ED visits and
						hospitalizations will result in loss of revenue that is not
						accounted for in the Quick Calculator. For these types of
						partnerships, the Deep Dive Calculator will be better able to
						capture revenue losses as well as gains.
					</li>
				</ol>
			</div>
			<div>
				<>
					{showModal ? (
						<>
							<DialogBox dialogBoxHandler={setShowModal}>
								<Definition
									{...selectedDefinition}
									closeHandler={() => {
										setShowModal(false);
										setSelectedDefinition(null);
									}}
								></Definition>
							</DialogBox>
						</>
					) : null}
				</>
			</div>
		</div>
	);
};
