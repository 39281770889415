import { SamplesActionTypes } from "./samples.action";

interface SamplesStateType {
  dummy: boolean;
}
const initialState: SamplesStateType = {
  dummy: false,
};
export const SamplesReducer = (
  state: SamplesStateType = initialState,
  action: SamplesActionTypes
) => {
  switch (action.type) {
    default:
      return state;
  }
};
