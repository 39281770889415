import { ButtonPropsType } from '../../interface/button-props-type.interface';
import './secondary-outline-button.css';

export const SecondaryOutlineButton = (props: ButtonPropsType) => {
	return (
		<button
			className={`${props.buttonType || 'small'} 
        w-full flex justify-center items-center 
        bg-white text-secondary border-[0.3rem] border-secondary 
        rounded-full font-colfax font-normal box-border
        enabled:hover:bg-secondary enabled:hover:text-white
        disabled:bg-white disabled:text-[#99DFDB] disabled:border-[#99DFDB]
        `}
			onClick={props.clickHandler}
			disabled={props.isDisabled}
		>
			{props.label}
		</button>
	);
};
