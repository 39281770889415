import { ButtonPropsType } from '../../interface/button-props-type.interface';
import './tertiary-button.css';

export const TertiaryButton = (props: ButtonPropsType) => {
	return (
		<button
			className={`${props.buttonType || 'small'} 
        w-full flex justify-center items-center 
				bg-[#F3E918] text-[#165E97] rounded-full 
				font-colfax font-normal box-border
        disabled:bg-[#F9F48B]  disabled:text-[#A2BDBD]
		border-[0.1rem] border-[#165E97]
        `}
			onClick={props.clickHandler}
			disabled={props.isDisabled}
		>
			{props.label}
		</button>
	);
};
