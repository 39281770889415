import { ButtonPropsType } from '../../interface/button-props-type.interface';
import './primary-outline-button.css';

export const PrimaryOutlineButton = (props: ButtonPropsType) => {
    return (
        <button
            className={`${props.buttonType || 'small'} 
        w-full flex justify-center items-center 
        bg-white text-[#165E97] border-[0.1rem] ${props.borderType === 'secondary' ? 'border-[#707070] ': 'border-[#165E97] ' }
        rounded-full font-colfax font-normal box-border
        enabled:hover:bg-primary enabled:hover:text-white
        disabled:bg-white disabled:text-[#8DB0CC] disabled:border-[#CECECE]
        `}
            onClick={props.clickHandler}
            disabled={props.isDisabled}
        >
            {props.label}
        </button>
    );
};
