export const RightForMySituationConfiguration = {
	items: [
		{
			label: 'Does the healthcare partner have aligned payment incentives? ',
			content:
				'This tool will be most useful when a healthcare organization has an incentive to reduce avoidable healthcare utilization. Medicare, Medicaid, and commercial insurers are developing value-based payment models focused on equity and, in some cases, paying directly to support community social services. For some healthcare organizations, such as hospitals paid under fee-for-service, reductions in ED visits and utilization (especially in commercial populations) will actually result in reductions in revenue. The Quick Calculator will not capture this reduction but the Deep Dive Calculator will allow you to incorporate the impact of revenue loss.',
		},
		{
			label: 'Do you plan to serve a high-needs population? ',
			content:
				' This tool will allow you to calculate potential healthcare savings for any adult population, but is most useful for adults with complex healthcare and social needs. That is because social service interventions are more likely to save money for populations that are using more expensive healthcare services due to unmet social needs. Social services may benefit other populations such as children but not save money if their healthcare use is low.',
		},
		{
			label: 'Is financial ROI the appropriate focus for your partnership? ',
			content:
				' This tool can help you assess the potential financial implications of a cross-sector partnership, or the financial trade-offs between building a social service or contracting with an existing community resource. This tool will not capture all of the potential costs associated with building a social service resource or the potential benefits of contracting with a community organization resource.',
		},
	],
};
