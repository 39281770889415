import { useEffect, useState } from 'react';
import { SecondaryOutlineButton } from '../../../shared/components/secondary-outline-button/secondary-outline-button';
import { TertiaryButton } from '../../../shared/components/tertiary-button/tertiary-button';
import './welcome-screen.css';
import { ListView } from '../components/list-view/list-view';
import { helpMeConfiguration } from '../config/help.me.config';
import { RightForMySituationConfiguration } from '../config/situation.config';
import { ConsiderRoi } from '../components/consider-roi/consider-roi';
import { useNavigate } from 'react-router';
import { DeepDive } from '../components/deep-dive/deep-dive';
import { DialogBox } from '../../../shared/components/dialog-box/dialog-box';
import { Definition } from '../components/definitaion/definition';
import { useDispatch } from 'react-redux';
import * as SharedActions from '../../../shared/core/shared.action';

export const WelcomeScreen = () => {
	/* variable */
	const helpMeConfig = helpMeConfiguration;
	const rightForMySituationConfig = RightForMySituationConfiguration;
	const navigate = useNavigate();
	const dispatch = useDispatch();

	/* state */
	const [helpMePanel, setHelpMePanel] = useState(false);
	const [rightForMePanel, setRightForMePanel] = useState(false);
	const [shouldIUsePanel, setShouldIUsePanel] = useState(false);
	const [considerPanel, setConsiderPanel] = useState(false);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		dispatch(SharedActions.resetCalculator());
	}, []);

	/* methods */
	const onPanelClickHandler = (panelType: string) => {
		if (panelType === 'helpMePanel') {
			const currentValue = helpMePanel;
			setHelpMePanel(!currentValue);
		} else if (panelType === 'rightForMePanel') {
			const currentValue = rightForMePanel;
			setRightForMePanel(!currentValue);
		} else if (panelType === 'shouldIUsePanel') {
			const currentValue = shouldIUsePanel;
			setShouldIUsePanel(!currentValue);
		} else if (panelType === 'considerPanel') {
			const currentValue = considerPanel;
			setConsiderPanel(!currentValue);
		}
	};

	const onQuestionButtonClicked = () => {
		window.open(
			'https://share.hsforms.com/10wbTH1l5Q166QSkjpbS1Yw5ln95',
			'_blank',
		);
	};

	const navigateUserToDeepDiveCalculator = () => {
		window.open(
			'https://dgxve27ji4aao.cloudfront.net/',
			'_blank',
		);
	}

	const navigateToStepByStepGuide = () => {
		window.open(
			'https://healthbegins.org/wp-content/uploads/2023/10/Step-by-Step-Guide.docx.pdf',
			'_blank',
		);
	}

	const navigateUserToCalculator = () => {
		navigate('/calculator/workflow-selection');
	};

	const navigateToDataCheckList = () => {
		window.open(
			'https://healthbegins.org/wp-content/uploads/2023/10/Final-Data-Checklist-READY.pdf',
			'_blank',
		);
	};

	const navigateToResourcesLink = () => {
		window.open(
			'https://healthbegins.org/partnership-resources/',
			'_blank',
		);
	}

	const navigateToCommonFunds = () => {
		window.open(
			'https://www.commonwealthfund.org/',
			'_blank',
		);
	}

	const navigateToScanFoundations = () => {
		window.open(
			'https://www.thescanfoundation.org/',
			'_blank',
		);
	}

	const navigateUserToComplexNeedGuide = () => {
		setShowModal(true);
	};


	const navigateTobaselineHealthcareUtilization = () => {
		window.open(
			'https://healthbegins.org/wp-content/uploads/2023/10/Final-Average-Healthcare-Cost-and-Utilization-Tables-READY.pdf',
			'_blank',
		);


	}


	const navigateToGuideToEvidence = () => {
		window.open(
			'https://healthbegins.org/wp-content/uploads/2023/12/ROI-Calculator-Evidence-Guide-.pdf',
			'_blank',
		);


	}

	const navigateToUsingAToolToMeasure = () => {
		window.open(
			'https://healthbegins.org/wp-content/uploads/2023/09/McCarthy_using_ROI_calculator_health_related_social_needs_ib_FINAL.pdf',
			'_blank',
		);
	}

	const navigateToHealthCareAccessNow = () => {
		window.open(
			'https://healthbegins.org/wp-content/uploads/2023/09/McCarthy_HCAN_ROI_Calculator_Use_Case_v2.pdf',
			'_blank',
		);
	}

	const navigateToHomage = () => {
		window.open(
			'https://healthbegins.org/wp-content/uploads/2023/09/Tabbush_Homage_ROI_calculator_use_case.pdf',
			'_blank',
		);
	}

	const navigateToOneStopShopForPartnership = () => {
		window.open(
			'https://healthbegins.org/partnership-resources/',
			'_blank',
		);
	}

	return (
		<div className='w-full flex'>
			<div className='w-[60%]  py-[2rem] px-[5rem]'>
				<div className='font-colfax heading-content border-box'>
					This calculator is designed to help community-based organizations and
					healthcare organizations plan and develop sustainable partnerships to
					fund the delivery of social services—such as housing, food,
					transportation, home modifications, legal aid, and financial
					counseling—for{' '}
					<a
						onClick={navigateUserToComplexNeedGuide}
						className='underline cursor-pointer inline'
					>
						people with complex needs
					</a>
					. This calculator will determine the healthcare savings a healthcare
					organization might expect as a result of a social service investment,
					also known as their financial Return on Investment (ROI).
				</div>
				<div className='text-primary font-montserrat mt-[5rem]'>
					<div
						className='expandable-heading flex items-center '
						onClick={() => {
							onPanelClickHandler('helpMePanel');
						}}
					>
						<span className='definition-clickable flex items-center cursor-pointer'>
							<span className='cursor-pointer'>
								What is the ROI Calculator intended to do?
							</span>{' '}
							<span className='icon ml-[0.8rem] cursor-pointer'>
								{helpMePanel ? '-' : '+'}{' '}
							</span>
						</span>
					</div>
					<div>{helpMePanel && <ListView {...helpMeConfig}></ListView>}</div>
				</div>

				<div className='text-primary font-montserrat mt-[5rem]'>
					<div
						className='expandable-heading flex items-center'
						onClick={() => {
							onPanelClickHandler('rightForMePanel');
						}}
					>
						<span className='definition-clickable flex items-center cursor-pointer'>
							<span className='cursor-pointer '>
								Is my organization ready to use the ROI Calculator?
							</span>{' '}
							<span className='icon ml-[0.8rem] cursor-pointer '>
								{rightForMePanel ? '-' : '+'}
							</span>
						</span>
					</div>
					<div>
						{rightForMePanel && <ListView {...rightForMySituationConfig} />}
					</div>
				</div>
				<div className='text-primary font-montserrat mt-[5rem]'>
					<div
						className='expandable-heading flex items-center'
						onClick={() => {
							onPanelClickHandler('shouldIUsePanel');
						}}
					>
						<span className='definition-clickable cursor-pointer'>
							<span className='cursor-pointer'>
								When should I use the Quick Calculator versus the Deep Dive
								Calculator?
							</span>
							<span className='icon ml-[0.8rem] align-bottom cursor-pointer'>
								{shouldIUsePanel ? '-' : '+'}{' '}
							</span>
						</span>{' '}
					</div>
					<div>{shouldIUsePanel && <DeepDive />}</div>
				</div>
				<div className='text-primary font-montserrat mt-[5rem]'>
					<div
						className='expandable-heading flex items-center'
						onClick={() => {
							onPanelClickHandler('considerPanel');
						}}
					>
						<span className='definition-clickable flex items-center cursor-pointer'>
							<span className='cursor-pointer'>
								What else should I consider when calculating ROI?
							</span>{' '}
							<span className='icon ml-[0.8rem] cursor-pointer'>
								{considerPanel ? '-' : '+'}
							</span>
						</span>
					</div>
					<div>{considerPanel && <ConsiderRoi></ConsiderRoi>}</div>
				</div>
				<div className='w-full my-[5rem]'>
					{/* 	<hr className='dashed-line' /> */}
				</div>
				<div className='border-[0.4rem] border-[#32BEB7] help-section my-[2rem] '>
					<div
						className='text-primary help-heading font-montserrat p-[1.5rem]'
					>
						Help and Reference Documents
					</div>
					<div className='help-content font-colfax p-[1.5rem]'>
						{/* Click
						<a
							className='help-hyperlink font-colfax mx-[0.4rem] cursor-pointer'
							onClick={navigateToResourcesLink}
						>
							here
						</a>
						to view a list of resources such as */}
						<li className='no-bullet'>
							A <a className='cursor-pointer no-bullet definition-clickable start-hyperlink' onClick={navigateToOneStopShopForPartnership}>One-Stop Shop for Partnership</a> resources that include the ones listed above and more
						</li>
						<div className='mt-[0.5rem] pl-[2rem]'>

							<ul className='list-disc'>
								<li >
									Getting started: <a className='cursor-pointer definition-clickable start-hyperlink' onClick={navigateToStepByStepGuide}> a step-by-step guide</a> and <a className='cursor-pointer definition-clickable start-hyperlink' onClick={navigateToDataCheckList}>data checklist</a>
								</li>
								<li >National benchmarks for <a className='cursor-pointer definition-clickable start-hyperlink' onClick={navigateTobaselineHealthcareUtilization}>baseline healthcare utilization</a></li>
								<li >
									A <a className='cursor-pointer definition-clickable start-hyperlink' onClick={navigateToGuideToEvidence}>guide to evidence</a> on the impact of social service
									interventions
								</li>
								<li >Case studies on the tool's use:</li>
								<li>
									<a className='cursor-pointer definition-clickable start-hyperlink' onClick={navigateToUsingAToolToMeasure}>Case studies: {' '}</a>
									Using a Tool to Measure Return on Investment in Addressing Health-Related Social Needs
								</li>
								<li><a className='cursor-pointer definition-clickable start-hyperlink' onClick={navigateToHealthCareAccessNow}>Case studies: {' '}</a>Health Care Access Now: Calculating the ROI of a Care Coordination Program to Address Social Determinants of Health</li>
								<li><a className='cursor-pointer definition-clickable start-hyperlink' onClick={navigateToHomage}>Case studies: {' '}</a>
									Homage: Calculating the ROI of a Partnership to Meet the Health-Related Social Needs of Medicare Advantage Plan Members</li>

							</ul>

						</div>
					</div>
				</div>
				<div className='help-content font-colfax p-[1.5rem]'>
					The ROI Calculator was supported by the <a className='cursor-pointer definition-clickable start-hyperlink' onClick={navigateToCommonFunds}>
						Commonwealth Fund
					</a> and the
					<a className='cursor-pointer start-hyperlink definition-clickable' onClick={navigateToScanFoundations}>
						{' '}SCAN
						Foundation
					</a>
					. The original calculator was developed by Dr. Victor
					Tabbush.
				</div>
			</div>
			<div className='w-[40%] bg-[#D9D9D9] min-h-[100vh] px-[3rem]'>
				<div className='w-[60%] md:w-[100%] sm:w-[100%] xs:w-[100%] mt-[5rem]'>
					<TertiaryButton
						clickHandler={() => {
							navigateUserToCalculator();
						}}
						label='Quick Calculator Start'
						buttonType='large'
					></TertiaryButton>
				</div>
				<div className='mt-[2.5rem] w-full font-colfax text-primary start-content'>
					Start here. This tool prepopulates healthcare utilization and cost
					data to get you started quickly. Our
					<a className='start-hyperlink cursor-pointer' onClick={navigateToDataCheckList}>
						{' '}data checklist{' '}
					</a>
					outlines the data
					you need to have ready and the
					<a className='start-hyperlink cursor-pointer' onClick={navigateToStepByStepGuide}>
						{' '}step by step guide{' '}
					</a>
					provides more
					information on how to complete each step. Completion of the calculator
					should take about 20 minutes.
				</div>
				<div className='w-[70%] md:w-[100%] sm:w-[100%] xs:w-[100%] mt-[5rem]'>
					<TertiaryButton
						clickHandler={() => {
							navigateUserToDeepDiveCalculator();
						}}
						label='Legacy Deep Dive Calculator'
						buttonType='large'
					></TertiaryButton>
				</div>
				<div className='mt-[2.5rem] w-full font-colfax text-primary start-content'>
					Use this Legacy Calculator to manually enter in data and calculate multiple interventions at once. Click {' '}
					<a className='start-hyperlink cursor-pointer' onClick={navigateToStepByStepGuide}>
						here{' '}
					</a>{' '}
					to access the step-by-step guide and for details on risks in using this tool.
				</div>
				<div className='mt-[2.5rem] w-full font-colfax text-primary start-content'>
					<iframe
						allowFullScreen={true}
						className='w-[90%] h-[26.8rem] video-player'
						src='https://www.youtube.com/embed/LxeINDpb0Dk?si=CmlsBUZDdHyAH-UV'
						title='YouTube video player'
						frameBorder={'0'}
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					></iframe>
				</div>
				<div className='w-[80%] md:w-[100%] sm:w-[100%] xs:w-[100%] mt-[10rem]'>
					<SecondaryOutlineButton
						clickHandler={() => {
							onQuestionButtonClicked();
						}}
						label='QUESTIONS & FEEDBACK'
						buttonType='large'
					></SecondaryOutlineButton>
				</div>
			</div>
			<>
				{showModal ? (
					<>
						<DialogBox dialogBoxHandler={setShowModal}>
							<Definition
								label={'Adults with Complex Needs'}
								content={
									'<b>Adults with Complex Needs: </b>Individuals with multiple chronic physical and behavioral health conditions who often face social barriers such as homelessness and unstable housing, food insecurity, and/or lack of transportation. This diverse group also includes frail, elderly adults and people with functional limitations or serious illness. <a style="text-decoration: underline" href="https://camdenhealth.org/about-us/what-is-complex-care/" target="_blank"  (The Camden Coalition)</a>'
								}
								closeHandler={() => {
									setShowModal(false);
								}}
							></Definition>
						</DialogBox>
					</>
				) : null}
			</>
		</div>
	);
};
