import { ListViewPropsType } from '../../model/list-view.props.type';
import './list-view.css';

export const ListView = (props: ListViewPropsType) => {
	return (
		<div className='w-full text-black'>
			{props.heading && (
				<div className='font-colfax list-view-content mt-[1.8rem]'>
					{props.heading}
				</div>
			)}
			<div>
				<ol className='list-decimal pl-[2rem]'>
					{props.items.map((item) => (
						<li className='font-colfax list-view-content mt-[2rem]'>
							<span className='list-view-label'>{item.label}</span>{' '}
							{item.content}
						</li>
					))}
				</ol>
			</div>
		</div>
	);
};
