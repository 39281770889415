import { SharedActionType } from './shared.action';
import * as SharedAction from './shared.action';
interface SharedStateType {
	calculator: any;
	socialService: {
		socialService: string;
		recipientsNumber: number;
		totalService: number;
	};
	revenueImplication: any;
	currentUtilization: any;
	targetPopulation: any;
	healthcareUtilization: any;
	financialReturnSummary: any;
}

const initialState: SharedStateType = {
	calculator: null,
	socialService: {
		socialService: '',
		recipientsNumber: 0,
		totalService: 0,
	},
	revenueImplication: {},
	currentUtilization: [],
	targetPopulation: {},
	healthcareUtilization: [],
	financialReturnSummary: {},
};

export const SharedReducer = (
	state: SharedStateType = initialState,
	action: SharedActionType,
) => {
	switch (action.type) {
		case SharedAction.UPDATE_CALCULATOR_VALUE:
			return {
				...state,
				calculator: { ...state.calculator, ...action.payload },
			};

		case SharedAction.RESET_CALCULATOR:
			return { ...state, calculator: null };

		case SharedAction.SET_SOCIAL_SERVICE_CALCULATED_DATA:
			return { ...state, socialService: { ...action.payload } };

		case SharedAction.SET_REVENUE_IMPLICATION_CALCULATED_DATA:
			return { ...state, revenueImplication: { ...action.payload } };

		case SharedAction.SET_CURRENT_UTILIZATION_CALCULATED_DATA:
			return { ...state, currentUtilization: [...action.payload] };

		case SharedAction.SET_TARGET_POPULATION_CALCULATED_DATA:
			return { ...state, targetPopulation: { ...action.payload } };

		case SharedAction.SET_HEALTHCARE_UTILIZATION_CALCULATED_DATA:
			return { ...state, healthcareUtilization: [...action.payload] };

		case SharedAction.SET_FINANCIAL_RETURN_SUMMARY_CALCULATED_DATA:
			return { ...state, financialReturnSummary: { ...action.payload } };

		default:
			return state;
	}
};
