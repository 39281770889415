import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconPropType } from '../../interface/icon-props.type';

export const Icon = (props: IconPropType) => {
	const handleClick = () => {
		props.clickHandler();
	};
	return (
		<FontAwesomeIcon
			className='w-[2rem] h-[2rem]'
			onClick={handleClick}
			icon={props.icon}
		/>
	);
};
