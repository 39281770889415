export const SideNavigationItems = [
	{
		heading: '',
		items: [
			{
				label: 'Social Service Selection & IMPACT',
				link: '/calculator/workflow-selection',
			},
			{
				label: 'TARGET POPULATION & INTENSITY',
				link: '/calculator/social-service',
			},
			{
				label: 'Current Utilization & Costs',
				link: '/calculator/current-utilization',
			},
			{
				label: 'Social Service Costs',
				link: '/calculator/target-population',
			},
			{
				label: 'Impact on Healthcare Utilization',
				link: '/calculator/healthcare-utilization',
			},
			{
				label: 'HealthCARE  ORGANIZATION Revenue Implications',
				link: '/calculator/revenue-implication',
			},
			{
				label: 'RESULTS: HealthCARE ORGANIZATION RETURN ON INVESTMENT',
				link: '/calculator/summary-financial',
			},
			{
				label: 'RESULTS: community-based organization revenue',
				link: '/calculator/return-on-investment',
			},
		],
	},
];
