import logo from '../../../assets/images/svg/logo-white.svg';

export const Footer = () => {
	const logoClickHandler = () => {
		window.open('https://healthbegins.org/', '_blank');
	};

	return (
		<div className='w-full h-[38.4rem] bg-[#165E97] box-border items-end flex pb-[2.5rem]'>
			<img
				src={logo}
				alt=''
				className='w-[26.4rem] h-[8.9rem] cursor-pointer'
				onClick={logoClickHandler}
			/>
		</div>
	);
};
