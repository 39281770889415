import { SecondaryButton } from '../secondary-button/secondary-button';
import './minimized-header.css';
import logo from '../../../assets/images/svg/logo-white.svg';
import { PrimaryButton } from '../primary-button/primary-button';
import { PrimaryOutlineButton } from '../primary-outline-button/primary-outline-button';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import * as SharedAction from '../../core/shared.action';

export const MinimizedHeader = () => {
	/* state */

	/* variable */
	const navigate = useNavigate();
	const dispatch = useDispatch();
	/* methods */
	const onHelpButtonClicked = () => {
		window.open('https://healthbegins.org/partnership-resources/', '_blank');
	};

	const onResetButtonClickedHandler = () => {
		dispatch(SharedAction.resetCalculator());
		navigate('/welcome');
	};

	const onContactUsClickedHandler = () => {
		window.open(
			'https://share.hsforms.com/10wbTH1l5Q166QSkjpbS1Yw5ln95',
			'_blank',
		);
	};

	const navigateToWelcomePage = () => {
		window.open('https://healthbegins.org/', '_blank');
	};

	return (
		<div className='w-full px-0'>
			<div className='w-full flex w-full py-[1.9rem] items-center bg-primary'>
				<div className='w-[20%] pl-[5rem]'>
					<img
						src={logo}
						className='cursor-pointer'
						onClick={navigateToWelcomePage}
					/>
				</div>
				<div className='w-[80%] minimized-header-title  font-montserrat font-normal text-white'>
					Return on Investment (ROI) Calculator
				</div>
			</div>
			<div className='w-full flex items-center min-h-[7.4rem] items-center bg-[#F5F5F5]'>
				<div className='w-[80%] sm:w-[75%] xs:w-[75%]'>
					<div className='flex pl-[9.7rem] sm:pl-[0.5rem] xs:pl-[0.5rem]'>
						<div>
							<PrimaryOutlineButton
								clickHandler={onHelpButtonClicked}
								isDisabled={false}
								label='Help and Reference'
								borderType='secondary'
							></PrimaryOutlineButton>
						</div>
						<div className='pl-[1.9rem]'>
							<PrimaryOutlineButton
								clickHandler={onResetButtonClickedHandler}
								isDisabled={false}
								label='Reset and Start Again'
								borderType='secondary'
							></PrimaryOutlineButton>
						</div>
					</div>
				</div>
				<div className='w-[10%] sm:w-[20%] '>
					<div className='w-[16rem] sm:w[100%]'>
						<SecondaryButton
							clickHandler={onContactUsClickedHandler}
							isDisabled={false}
							label='Contact Us'
						></SecondaryButton>
					</div>
				</div>
			</div>
		</div>
	);
};
