import { Outlet } from 'react-router';
import { MinimizedHeader } from '../minimized-header/minimized-header';
import { SideNavigation } from '../side-nav/side-nav';
import './calculator-wrapper-.css';
import { Footer } from '../footer/footer';
import { useLocation } from 'react-router';

export const CalculatorWrapper = () => {
	const location = useLocation();

	return (
		<div className='w-full box-border'>
			<div className='w-full'>
				<MinimizedHeader></MinimizedHeader>
			</div>
			<div className='w-full flex mb-[2rem] mt-[1rem]'>
				<div className='min-w-[20%]'>
					<SideNavigation></SideNavigation>
				</div>
				<div className='w-80% pb-[1rem] px-[4rem] mt-[1rem]'>
					<Outlet></Outlet>
				</div>
			</div>
			<div className='w-full bottom-[0]'>
				<Footer></Footer>
			</div>
		</div>
	);
};
