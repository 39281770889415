import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { CalculatorWrapper } from './shared/components/calculator-wrapper/calculator.wrapper';
import { WelcomeScreen } from './pages/welcome-page/screen/welcome-screen';

const SamplesScreen = React.lazy(
	() => import(`./pages/samples/screen/samples-screen`),
);
const WelcomeWrapper = React.lazy(
	() => import('./pages/welcome-page/wrapper/welcome-wrapper'),
);
const WorkflowSelection = React.lazy(
	() => import('./pages/workflow-selection/screen/workflow-selection-screen'),
);
const SocialService = React.lazy(
	() => import(`./pages/social-service/screen/social-service-screen`),
);
const CurrentUtilization = React.lazy(
	() => import('./pages/current-utilization/screen/current-utilization-screen'),
);
const PageNotFound = React.lazy(
	() => import('./pages/page-not-found/screen/page-not-found-screen'),
);
const SummaryFinancial = React.lazy(
	() => import('./pages/summary-financial/screen/summary-financial-screen'),
);

const TargetPopulation = React.lazy(
	() => import('./pages/target-population/screen/target-population-screen'),
);

const HealthcareUtilization = React.lazy(
	() =>
		import(
			'./pages/healthcare-utilization/screen/healthcare-utilization-screen'
		),
);
const RevenueImplication = React.lazy(
	() => import('./pages/revenue-implication/screen/revenue-implication'),
);

const ReturnOnInvestment = React.lazy(
	() =>
		import('./pages/return-on-investment/screen/return-on-investment-screen'),
);

const PrintSummary = React.lazy(
	() => import('./pages/print-summary/print-summary'),
);

const AppRoutes: any = [
	{
		path: '',
		element: <Navigate to='welcome' replace />,
	},
	{
		path: 'samples',
		element: (
			<Suspense>
				<SamplesScreen></SamplesScreen>
			</Suspense>
		),
	},

	{
		path: 'welcome',
		element: (
			<Suspense>
				<WelcomeWrapper></WelcomeWrapper>
			</Suspense>
		),
		children: [
			{
				path: '',
				element: <WelcomeScreen />,
			},
		],
	},
	{
		path: 'calculator',
		element: (
			<Suspense>
				<CalculatorWrapper></CalculatorWrapper>
			</Suspense>
		),
		children: [
			{
				path: '',
				element: <Navigate to='workflow-selection' replace />,
			},
			{
				path: 'workflow-selection',
				element: (
					<Suspense>
						<WorkflowSelection />
					</Suspense>
				),
			},
			{
				path: 'social-service',
				element: (
					<Suspense>
						<SocialService />
					</Suspense>
				),
			},
			{
				path: 'current-utilization',
				element: (
					<Suspense>
						<CurrentUtilization />
					</Suspense>
				),
			},
			{
				path: 'target-population',
				element: (
					<Suspense>
						<TargetPopulation />
					</Suspense>
				),
			},
			{
				path: 'healthcare-utilization',
				element: (
					<Suspense>
						<HealthcareUtilization />
					</Suspense>
				),
			},
			{
				path: 'revenue-implication',
				element: (
					<Suspense>
						<RevenueImplication />
					</Suspense>
				),
			},
			{
				path: 'summary-financial',
				element: (
					<Suspense>
						<SummaryFinancial />
					</Suspense>
				),
			},
			{
				path: 'return-on-investment',
				element: (
					<Suspense>
						<ReturnOnInvestment />
					</Suspense>
				),
			},
			{
				path: 'print',
				element: (
					<Suspense>
						<PrintSummary></PrintSummary>
					</Suspense>
				),
			},
		],
	},
	{
		path: '*',
		element: (
			<Suspense>
				<PageNotFound />
			</Suspense>
		),
	},
];

export default AppRoutes;
