export const helpMeConfiguration = {
	heading: 'The calculator can help you: ',
	items: [
		{
			label: 'Determine ROI: ',
			content:
				'Define the healthcare cost savings and return on investment (ROI) that can be expected from the provision of selected social services to an identified population.',
		},
		{
			label: 'Make a Business Case: ',
			content:
				'Develop a business case based on expected ROI to inform planning and negotiations for a contract between a healthcare entity and a community based organization to deliver social services under a variety of payment methods.',
		},
		{
			label: 'Assess Different Financing Models: ',
			content:
				'Compare and select between different types of financing models including full cost recovery, fee-for-service, case rates, capitation, and shared savings.',
		},
		/* {
			label: 'Evaluate Impact: ',
			content:
				'Evaluate your experience to refine and sustain the partnership arrangement over time.',
		}, */
	],
};
