import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import AppRoutes from './app-routes';
import { useSelector } from 'react-redux';
function App() {
	/* state */

	/* variables */
	const location = useLocation();
	const routes = useRoutes(AppRoutes);
	const navigate = useNavigate();
	const calculatorState = useSelector(
		(state: any) => state?.shared?.calculator,
	);
	/* selectors */

	/* useEffects */
	useEffect(() => {
		if (
			location.pathname != '/welcome' &&
			location.pathname != '/calculator/workflow-selection' &&
			calculatorState === null
		) {
			navigate('/welcome');
		}
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	/* methods */

	return <div className='App'>{routes}</div>;
}

export default App;
