import './consider-roi.css';
import ValueOfInvestmentChart from '../../../../assets/images/png/investment-crop.png';

export const ConsiderRoi = () => {
	/* images */
	const ValueOfInvestment = ValueOfInvestmentChart;

	return (
		<div className='font-colfax mt-[2rem]'>
			
			<div className='mt-[2rem] flex-col border-secondary border-[0.4rem] p-[3rem] value-of-investment-section'>
				<div className='my-[0.6rem] font-colfax text-black value-heading'>
					Value of Investment
				</div>
				<div className='w-[100%] consider-content font-colfax text-black'>
					<img
						className='float-right w-[40%] px-[1.2rem]'
						src={ValueOfInvestment}
						alt=''
					/>
					<div>
						Using this calculator to estimate return on investment (ROI) is one
						piece of estimating the overall value of a healthcare investment in
						social resources. Estimating overall value of the investment
						requires pairing this type of ROI calculation with economic returns
						(all the tangible and intangible benefits that confer economic value
						to each partner institution, such as boosts to capacity,
						productivity, or branding and goodwill) and social returns (benefits
						that accrue to a broader set of organizations, stakeholders, and
						constituents in a community). While the ROI information in this
						calculator provides insight into cost neutral investments, a deeper value of investment analysis should be included in
						developing reimbursement rates for community based organizations.
					</div>
				</div>
			</div>
		</div>
	);
};
