import { useLocation } from 'react-router';
import { SideNavigationItems } from '../../../config/side-nav.config';
import './side-nav.css';
import { useEffect } from 'react';

export const SideNavigation = () => {
	/* variables */
	const location = useLocation();
	const sideNavigationItems = SideNavigationItems;

	return (
		<div className='w-full bg-[#F5F5F5] flex-col items-center'>
			{sideNavigationItems.map((items, itemIndex) => (
				<div className='w-full flex-col' key={itemIndex}>
					<div
						key={itemIndex}
						className='item-label w-full flex px-[1.5rem] py-[1.2rem]'
					>
						{items.heading.length > 0 ? (
							<div>{items.heading.toUpperCase()}</div>
						) : (
							''
						)}
					</div>
					{items.items.map((items, index) => (
						<div
							key={index}
							className={
								'w-full font-colfax sub-item-label px-[1.5rem] py-[1.2rem] ' +
								(items.link === location.pathname
									? 'bg-secondary text-white'
									: '')
							}
						>
							{items.label.toUpperCase()}
						</div>
					))}
				</div>
			))}
		</div>
	);
};
