import { Icon } from '../../../../shared/components/icons/icon';
import './definition.css';
import { faXmark, faChevronDown } from '@fortawesome/free-solid-svg-icons';

interface DefinitionPropsType {
	label?: string;
	content: string;
	closeHandler: any;
}

export const Definition = (props: DefinitionPropsType) => {
	return (
		<>
			<div className='flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600'>
				<h3 className='font-colfax dialog-box-heading'>{props?.label}</h3>
				<button
					type='button'
					className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
					data-modal-hide='defaultModal'
					onClick={() => props.closeHandler(false)}
				>
					<Icon
						icon={faXmark}
						clickHandler={() => props.closeHandler(false)}
					></Icon>
				</button>
			</div>
			<div
				className='font-colfax dialog-box-content p-4'
				dangerouslySetInnerHTML={{ __html: props.content }}
			></div>
		</>
	);
};
