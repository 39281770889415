import { ButtonPropsType } from '../../interface/button-props-type.interface';
import './secondary-button.css';

export const SecondaryButton = (props: ButtonPropsType) => {
	/* methods */

	return (
		<button
			className={`
				${props.buttonType || 'small'} 
				w-full flex justify-center items-center 
        bg-[#32BEB7] 
        text-white 
        border-[0.1rem] 
        border-[#165E97] 
        rounded-full font-colfax font-normal box-border
        disabled:bg-[#98DEDB] disabled:text-[#FFFBF3]  disabled:border-#A9C6D9
			`}
			onClick={props.clickHandler}
			disabled={props.isDisabled}
		>

			{props.label}
		</button>
	);
};
